import striptags from 'striptags';
import he from 'he';
import { decodeHTMLEntities } from './common';

export const generateProductSchema = (product, metaDesc) => {
  const proDesc = product?.description
    ? decodeHTMLEntities(product.description).replace(
        /<p>|<\/p>|<strong>|<\/strong>|[^a-zA-Z0-9_ %\[\]\.\(\)%&-]/g,
        ' '
      )
    : '';
  const desc = proDesc || metaDesc;
  const clearDesc = striptags(he.decode(desc));
  const available =
    product?.quantity > 0
      ? 'https://schema.org/InStock'
      : 'https://schema.org/OutOfStock';
  const rating =
    product?.star === 0 || product?.star === '' ? 5 : product?.star;
  const count =
    product.rating === 0 || product.rating === '' ? 1 : product.rating;
  const url = `product/${product.url}`;

  const images = product.images.map(
    (item) =>
      `${process.env.NEXT_PUBLIC_PRODUCT_IMAGE_S3_BUCKET_BASE_URL}${item.image}`
  );

  const currentYear = new Date().getFullYear();
  const priceValidUntil = `${currentYear}-12-31`;
  const schemaData = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: product?.name,
    image: images,
    description: clearDesc,
    brand: {
      '@type': 'Brand',
      name: product.brand,
    },
    sku: product.sku,
    offers: {
      '@type': 'Offer',
      url: `${process.env.NEXT_PUBLIC_BASE_URL}/${url}`,
      priceValidUntil: priceValidUntil,
      priceSpecification: [
        {
          '@type': 'UnitPriceSpecification',
          price: parseInt(product.special_price),
          priceCurrency: 'INR',
        },
        {
          '@type': 'UnitPriceSpecification',
          priceType: 'https://schema.org/ListPrice',
          price: parseInt(product.price),
          priceCurrency: 'INR',
        },
      ],
      availability: available,
      itemCondition: 'https://schema.org/NewCondition',
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: rating,
      bestRating: '5',
      worstRating: '1',
      ratingCount: count,
    },
  };

  return JSON.stringify(schemaData, null, 2);
};

export const generateFaqSchema = (faq) => {
  const faqSchema = faq.map((faq) => ({
    '@type': 'Question',
    name: faq.question,
    acceptedAnswer: {
      '@type': 'Answer',
      text: decodeHTMLEntities(faq.answer),
    },
  }));

  const schemaData = {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: faqSchema,
  };

  return JSON.stringify(schemaData, null, 2);
};

export const generateListItemSchema = (data) => {
  const itemListElement = data?.map((cat, index) => ({
    '@type': 'ListItem',
    position: index + 1,
    name: cat.name,
    url: `${process.env.NEXT_PUBLIC_SITE_URL}/${cat.url}`,
  }));

  const schemaData = {
    '@context': 'https://schema.org/',
    '@type': 'ItemList',
    itemListElement: itemListElement,
  };

  return JSON.stringify(schemaData, null, 2);
};

export const generateBreadcumbSchema = (data) => {
  const itemListElement = data?.map((cat, index) => ({
    '@type': 'ListItem',
    position: index + 1,
    name: cat.text,
    item: `${process.env.NEXT_PUBLIC_SITE_URL}/${cat.href}`,
  }));

  const schemaData = {
    '@context': 'https://schema.org/',
    '@type': 'BreadcrumbList',
    itemListElement: itemListElement,
  };

  return JSON.stringify(schemaData, null, 2);
};

export const generateOnlineStore = () => {
  const schemaData = {
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'OnlineStore',
        name: 'Wooden Street',
        url: process.env.NEXT_PUBLIC_SITE_URL,
        sameAs: [
          'https://www.facebook.com/woodenstreet/',
          'https://x.com/wooden_street',
          'https://www.instagram.com/woodenstreet/',
          'https://www.youtube.com/c/woodenstreetofficial',
          'https://in.linkedin.com/company/wooden-street',
          'https://in.pinterest.com/woodenstreet/',
          'https://en.wikipedia.org/wiki/WoodenStreet',
        ],
        logo: `${process.env.NEXT_PUBLIC_SITE_URL}/images/svg/new-logo.svg`,
        contactPoint: {
          contactType: 'Customer Service',
          email: 'mailto:support@woodenstreet.com',
          telephone: '+91 9314444747',
        },
        taxID: '08AAFCT4410K1ZB',
        iso6523Code: '9001:U36100RJ2015PTC047992',
        hasMerchantReturnPolicy: {
          '@type': 'MerchantReturnPolicy',
          applicableCountry: ['IN'],
          returnPolicyCountry: 'IN',
          returnPolicyCategory: 'MerchantReturnFiniteReturnWindow',
          merchantReturnDays: 3,
          returnMethod: 'ReturnByMail',
          returnFees: 'FreeReturn',
          refundType: 'FullRefund',
        },
      },
    ],
  };

  return JSON.stringify(schemaData, null, 2);
};

export const generateOfflineFurniture = () => {
  const schemaData = {
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            name: 'Wooden Street',
            item: process.env.NEXT_PUBLIC_SITE_URL,
          },
          {
            '@type': 'ListItem',
            position: 2,
            name: 'Wooden Street Furniture Store',
            item: `${process.env.NEXT_PUBLIC_SITE_URL}/offline-furniture-store`,
          },
        ],
      },
      {
        '@type': 'FurnitureStore',
        name: 'Wooden Street Furniture Stores',
        image: `${process.env.NEXT_PUBLIC_IMAGE_S3_BUCKET_BASE_URL}/images/store-new/store-banner.jpg`,
        '@id': '',
        url: `${process.env.NEXT_PUBLIC_SITE_URL}/offline-furniture-store`,
        telephone: '+91-9314444747',
        address: {
          '@type': 'PostalAddress',
          addressCountry: 'IN',
        },
        openingHoursSpecification: {
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
          ],
          opens: '10:00',
          closes: '21:00',
        },
        sameAs: [
          'https://www.facebook.com/woodenstreet/',
          'https://x.com/wooden_street',
          'https://www.instagram.com/woodenstreet/',
          'https://www.youtube.com/c/woodenstreetofficial',
          'https://in.linkedin.com/company/wooden-street',
          'https://in.pinterest.com/woodenstreet/',
          'https://en.wikipedia.org/wiki/Wooden_Street',
          process.env.NEXT_PUBLIC_SITE_URL,
        ],
      },
    ],
  };

  return JSON.stringify(schemaData, null, 2);
};

export const generateHomeSchema = () => {
  const schemaData = {
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'Organization',
        name: 'Wooden Street Furniture Pvt Ltd',
        alternateName: 'Wooden Street',
        url: process.env.NEXT_PUBLIC_SITE_URL,
        logo: `${process.env.NEXT_PUBLIC_SITE_URL}/images/svg/new-logo.svg`,
        contactPoint: {
          '@type': 'ContactPoint',
          telephone: '+91-9314444747',
          contactType: 'customer service',
          areaServed: 'IN',
          availableLanguage: ['en', 'Hindi'],
        },
        sameAs: [
          'https://www.facebook.com/woodenstreet/',
          'https://twitter.com/wooden_street',
          'https://www.instagram.com/woodenstreet/',
          'https://www.youtube.com/c/woodenstreetofficial',
          'https://in.linkedin.com/company/wooden-street',
          'https://in.pinterest.com/woodenstreet/',
        ],
      },
    ],
  };

  return JSON.stringify(schemaData, null, 2);
};
