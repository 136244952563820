import WsStaticImage from '@/components/common/WsStaticImage';
import CustomLink from '@/components/CustomLink';
import { decodeHTMLEntities } from '@/utils/common';
import React from 'react';
import styles from './style.module.scss';

interface LargeDecoratingProps {
  items?: any;
}

const LargeDecorating: React.FC<LargeDecoratingProps> = ({ items }) => {
  return (
    <section className={styles.exploresofasection} style={items?.style}>
      <div className="container-fluid">
        <div className="wdRow ">
          <div className="wdCol-lg-12">
            {Boolean(items?.viewMore) ? (
              <div className={styles.flexContainer}>
                <div className={styles.exploresofaheading}>
                  <div className={styles['subtitle']}>{items.title}</div>
                  <p>{items.description}</p>
                </div>

                <a href={items.viewMore} className={styles.viewAll}>
                  View All
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.10059 3.8772L11.1006 8.87454L6.10059 13.8719"
                      stroke="#515151"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </a>
              </div>
            ) : (
              <div className={styles.exploresofaheading}>
                <div className={styles['subtitle']}>{items.title}</div>
                <p>{items.description}</p>
              </div>
            )}
          </div>
        </div>
        <div className={styles.exploresofacontent}>
          <div className="wdRow wdRow-cols-2 wdRow-cols-sm-2 wdRow-cols-md-3 wdRow-cols-lg-5 ">
            {items?.items?.map((list: any, index: number) => (
              <div className="wdCol" key={index}>
                <CustomLink href={list.href} className={styles.exploresofacard}>
                  <div className={styles.exploresofaimg}>
                    <figure>
                      <WsStaticImage
                        src={list.image}
                        alt={list.alt}
                        // style={{ width: '100%', height: '100%' }}
                        width={500}
                        height={500}
                        quality={100}
                        //// loading="lazy"
                        // sizes="100vw"
                      />
                    </figure>
                  </div>
                  <div className={styles.exploresofatext}>
                    <p className={styles['prices']}>
                      {decodeHTMLEntities(list.title)}
                    </p>
                    <p>{list.label}</p>
                  </div>
                </CustomLink>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default LargeDecorating;
